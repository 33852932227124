//Search results dropdown header
.ui-menu.sf-autocomplete {
    background: white;
    padding: 18px;
    list-style: none;
    margin-top: 3px;
    z-index: 10000;

    li {
        border-bottom: 1px solid #ccc;
        padding: 5px;
        list-style: none;
    }
}

//Search Results main page
.searchResults {
    .SearchTitle {
        margin: 3rem 0 4rem 0;

        h3 {
            font-weight: 700;
        }
    }
}

#SearchWrapper {
    margin: 3rem 0 4rem 0;

    @include media-breakpoint-up(lg) {
        margin: 5rem 0;
    }

    .media-body {
        margin-bottom: 3.5rem;
    }
}
