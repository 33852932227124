//Box Shadow
@mixin box-shadow( $horiz, $vert, $blur, $spread, $color ) {
    -webkit-box-shadow: $horiz $vert $blur $spread $color;
    -moz-box-shadow: $horiz $vert $blur $spread $color;
    box-shadow: $horiz $vert $blur $spread $color;
}

//Posisition absolute 
@mixin pos-absolute($positions...){
    position: absolute;
    @include position($positions...);
}

//Posisition Fixed 
@mixin pos-fixed($positions...){
  position: fixed;
  @include position($positions...);
}

@mixin position($positions...){
    $positions: parseDirections($positions);
    top: map-get($positions,'top');
    right: map-get($positions,'right');
    bottom: map-get($positions,'bottom');
    left: map-get($positions,'left');
  }

  @function parseDirections($directions){
    $top: 0; $bottom: 0; $left: 0; $right: 0;
      
    @if length($directions) > 0{
      $d1: nth($directions,1);
      $top: $d1; $bottom: $d1; $left: $d1; $right: $d1; 
    }@if length($directions) > 1 {
      $d2: nth($directions,2);
      $left: $d2; $right: $d2; 
    }@if length($directions) > 2 {
      $d3: nth($directions,3);
      $bottom: $d3;
    }@if length($directions) > 3 {
      $d4: nth($directions,4);
      $left: $d4
    }
    
    @return (
      top: $top,
      bottom: $bottom,
      left: $left,
      right: $right
    );
  }

  /*Border Radius*/
  @mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box;  /* stops bg color from leaking outside the border: */
  }

/*object fit*/
@mixin object-fit {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}