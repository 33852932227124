@charset "UTF-8";
/*Custom Styles*/
/*Border Radius*/
/*object fit*/
/*Bootsrap Styles*/
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 2px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 1101px !important;
  }
  .container {
    min-width: 1101px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 2px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #ccc;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #ccc;
  }
}
:root {
  --blue: #115577;
  --indigo: #6610f2;
  --purple: #553377;
  --pink: #e83e8c;
  --red: #C00010;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #71B750;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #aa7a1a;
  --secondary: #6c757d;
  --success: #255B0A;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #C00010;
  --light: #ccc;
  --dark: #000;
  --blue: #115577;
  --blue-dark: #0D4764;
  --blue-darkest: #043246;
  --silver-light: #e8e8e8;
  --silver-lighter: #eee;
  --silver-lightest: #fbfbfb;
  --silver: #ccc;
  --silver-dark: #888;
  --silver-darker: #555;
  --silver-darkest: #333;
  --red: #C00010;
  --red-dark: #A1000D;
  --red-darkest: #7D000A;
  --green: #71B750;
  --green-dark: #255B0A;
  --green-darkest: #1B4606;
  --purple: #553377;
  --purple-dark: #3E235A;
  --purple-darkest: #2B183E;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1101px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "JostVariableFont", arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.lead {
  font-weight: 300;
}

.display-1 {
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  /*padding-left: 0;
  list-style: none;*/
}

.list-inline {
  /*padding-left: 0;
  list-style: none;*/
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 2px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 1101px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1101px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 2px solid #ccc;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 4px solid #ccc;
}
.table tbody + tbody {
  border-top: 4px solid #ccc;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 2px solid #ccc;
}
.table-bordered th,
.table-bordered td {
  border: 2px solid #ccc;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 4px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #e7dabf;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #d3ba88;
}

.table-hover .table-primary:hover {
  background-color: #e0cfac;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #e0cfac;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c2d1ba;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8eaa80;
}

.table-hover .table-success:hover {
  background-color: #b4c7ab;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b4c7ab;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #edb8bc;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #de7a83;
}

.table-hover .table-danger:hover {
  background-color: #e8a4a9;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #e8a4a9;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f1f1f1;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #e4e4e4;
}

.table-hover .table-light:hover {
  background-color: #e4e4e4;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #e4e4e4;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #b8b8b8;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #7a7a7a;
}

.table-hover .table-dark:hover {
  background-color: #ababab;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #ababab;
}

.table-blue,
.table-blue > th,
.table-blue > td {
  background-color: #bccfd9;
}
.table-blue th,
.table-blue td,
.table-blue thead th,
.table-blue tbody + tbody {
  border-color: #83a7b8;
}

.table-hover .table-blue:hover {
  background-color: #acc3d0;
}
.table-hover .table-blue:hover > td,
.table-hover .table-blue:hover > th {
  background-color: #acc3d0;
}

.table-blue-dark,
.table-blue-dark > th,
.table-blue-dark > td {
  background-color: #bbcbd4;
}
.table-blue-dark th,
.table-blue-dark td,
.table-blue-dark thead th,
.table-blue-dark tbody + tbody {
  border-color: #819fae;
}

.table-hover .table-blue-dark:hover {
  background-color: #abbfca;
}
.table-hover .table-blue-dark:hover > td,
.table-hover .table-blue-dark:hover > th {
  background-color: #abbfca;
}

.table-blue-darkest,
.table-blue-darkest > th,
.table-blue-darkest > td {
  background-color: #b9c6cb;
}
.table-blue-darkest th,
.table-blue-darkest td,
.table-blue-darkest thead th,
.table-blue-darkest tbody + tbody {
  border-color: #7c949f;
}

.table-hover .table-blue-darkest:hover {
  background-color: #aabac0;
}
.table-hover .table-blue-darkest:hover > td,
.table-hover .table-blue-darkest:hover > th {
  background-color: #aabac0;
}

.table-silver-light,
.table-silver-light > th,
.table-silver-light > td {
  background-color: #f9f9f9;
}
.table-silver-light th,
.table-silver-light td,
.table-silver-light thead th,
.table-silver-light tbody + tbody {
  border-color: #f3f3f3;
}

.table-hover .table-silver-light:hover {
  background-color: #ececec;
}
.table-hover .table-silver-light:hover > td,
.table-hover .table-silver-light:hover > th {
  background-color: #ececec;
}

.table-silver-lighter,
.table-silver-lighter > th,
.table-silver-lighter > td {
  background-color: #fafafa;
}
.table-silver-lighter th,
.table-silver-lighter td,
.table-silver-lighter thead th,
.table-silver-lighter tbody + tbody {
  border-color: #f6f6f6;
}

.table-hover .table-silver-lighter:hover {
  background-color: #ededed;
}
.table-hover .table-silver-lighter:hover > td,
.table-hover .table-silver-lighter:hover > th {
  background-color: #ededed;
}

.table-silver-lightest,
.table-silver-lightest > th,
.table-silver-lightest > td {
  background-color: #fefefe;
}
.table-silver-lightest th,
.table-silver-lightest td,
.table-silver-lightest thead th,
.table-silver-lightest tbody + tbody {
  border-color: #fdfdfd;
}

.table-hover .table-silver-lightest:hover {
  background-color: #f1f1f1;
}
.table-hover .table-silver-lightest:hover > td,
.table-hover .table-silver-lightest:hover > th {
  background-color: #f1f1f1;
}

.table-silver,
.table-silver > th,
.table-silver > td {
  background-color: #f1f1f1;
}
.table-silver th,
.table-silver td,
.table-silver thead th,
.table-silver tbody + tbody {
  border-color: #e4e4e4;
}

.table-hover .table-silver:hover {
  background-color: #e4e4e4;
}
.table-hover .table-silver:hover > td,
.table-hover .table-silver:hover > th {
  background-color: #e4e4e4;
}

.table-silver-dark,
.table-silver-dark > th,
.table-silver-dark > td {
  background-color: #dedede;
}
.table-silver-dark th,
.table-silver-dark td,
.table-silver-dark thead th,
.table-silver-dark tbody + tbody {
  border-color: #c1c1c1;
}

.table-hover .table-silver-dark:hover {
  background-color: #d1d1d1;
}
.table-hover .table-silver-dark:hover > td,
.table-hover .table-silver-dark:hover > th {
  background-color: #d1d1d1;
}

.table-silver-darker,
.table-silver-darker > th,
.table-silver-darker > td {
  background-color: #cfcfcf;
}
.table-silver-darker th,
.table-silver-darker td,
.table-silver-darker thead th,
.table-silver-darker tbody + tbody {
  border-color: #a7a7a7;
}

.table-hover .table-silver-darker:hover {
  background-color: #c2c2c2;
}
.table-hover .table-silver-darker:hover > td,
.table-hover .table-silver-darker:hover > th {
  background-color: #c2c2c2;
}

.table-silver-darkest,
.table-silver-darkest > th,
.table-silver-darkest > td {
  background-color: #c6c6c6;
}
.table-silver-darkest th,
.table-silver-darkest td,
.table-silver-darkest thead th,
.table-silver-darkest tbody + tbody {
  border-color: #959595;
}

.table-hover .table-silver-darkest:hover {
  background-color: #b9b9b9;
}
.table-hover .table-silver-darkest:hover > td,
.table-hover .table-silver-darkest:hover > th {
  background-color: #b9b9b9;
}

.table-red,
.table-red > th,
.table-red > td {
  background-color: #edb8bc;
}
.table-red th,
.table-red td,
.table-red thead th,
.table-red tbody + tbody {
  border-color: #de7a83;
}

.table-hover .table-red:hover {
  background-color: #e8a4a9;
}
.table-hover .table-red:hover > td,
.table-hover .table-red:hover > th {
  background-color: #e8a4a9;
}

.table-red-dark,
.table-red-dark > th,
.table-red-dark > td {
  background-color: #e5b8bb;
}
.table-red-dark th,
.table-red-dark td,
.table-red-dark thead th,
.table-red-dark tbody + tbody {
  border-color: #ce7a81;
}

.table-hover .table-red-dark:hover {
  background-color: #dea5a9;
}
.table-hover .table-red-dark:hover > td,
.table-hover .table-red-dark:hover > th {
  background-color: #dea5a9;
}

.table-red-darkest,
.table-red-darkest > th,
.table-red-darkest > td {
  background-color: #dbb8ba;
}
.table-red-darkest th,
.table-red-darkest td,
.table-red-darkest thead th,
.table-red-darkest tbody + tbody {
  border-color: #bb7a80;
}

.table-hover .table-red-darkest:hover {
  background-color: #d2a7aa;
}
.table-hover .table-red-darkest:hover > td,
.table-hover .table-red-darkest:hover > th {
  background-color: #d2a7aa;
}

.table-green,
.table-green > th,
.table-green > td {
  background-color: #d7ebce;
}
.table-green th,
.table-green td,
.table-green thead th,
.table-green tbody + tbody {
  border-color: #b5daa4;
}

.table-hover .table-green:hover {
  background-color: #c8e4bc;
}
.table-hover .table-green:hover > td,
.table-hover .table-green:hover > th {
  background-color: #c8e4bc;
}

.table-green-dark,
.table-green-dark > th,
.table-green-dark > td {
  background-color: #c2d1ba;
}
.table-green-dark th,
.table-green-dark td,
.table-green-dark thead th,
.table-green-dark tbody + tbody {
  border-color: #8eaa80;
}

.table-hover .table-green-dark:hover {
  background-color: #b4c7ab;
}
.table-hover .table-green-dark:hover > td,
.table-hover .table-green-dark:hover > th {
  background-color: #b4c7ab;
}

.table-green-darkest,
.table-green-darkest > th,
.table-green-darkest > td {
  background-color: #bfcbb9;
}
.table-green-darkest th,
.table-green-darkest td,
.table-green-darkest thead th,
.table-green-darkest tbody + tbody {
  border-color: #889f7e;
}

.table-hover .table-green-darkest:hover {
  background-color: #b2c0aa;
}
.table-hover .table-green-darkest:hover > td,
.table-hover .table-green-darkest:hover > th {
  background-color: #b2c0aa;
}

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #cfc6d9;
}
.table-purple th,
.table-purple td,
.table-purple thead th,
.table-purple tbody + tbody {
  border-color: #a795b8;
}

.table-hover .table-purple:hover {
  background-color: #c2b7cf;
}
.table-hover .table-purple:hover > td,
.table-hover .table-purple:hover > th {
  background-color: #c2b7cf;
}

.table-purple-dark,
.table-purple-dark > th,
.table-purple-dark > td {
  background-color: #c9c1d1;
}
.table-purple-dark th,
.table-purple-dark td,
.table-purple-dark thead th,
.table-purple-dark tbody + tbody {
  border-color: #9b8da9;
}

.table-hover .table-purple-dark:hover {
  background-color: #bcb2c6;
}
.table-hover .table-purple-dark:hover > td,
.table-hover .table-purple-dark:hover > th {
  background-color: #bcb2c6;
}

.table-purple-darkest,
.table-purple-darkest > th,
.table-purple-darkest > td {
  background-color: #c4bec9;
}
.table-purple-darkest th,
.table-purple-darkest td,
.table-purple-darkest thead th,
.table-purple-darkest tbody + tbody {
  border-color: #91879b;
}

.table-hover .table-purple-darkest:hover {
  background-color: #b7b0bd;
}
.table-hover .table-purple-darkest:hover > td,
.table-hover .table-purple-darkest:hover > th {
  background-color: #b7b0bd;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #ccc;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1100.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 4px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #e6b95d;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(170, 122, 26, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 2px);
  padding-bottom: calc(0.375rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 2px);
  padding-bottom: calc(0.5rem + 2px);
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 2px);
  padding-bottom: calc(0.25rem + 2px);
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 4px);
  padding: 0.25rem 0.5rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 4px);
  padding: 0.5rem 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #255B0A;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(37, 91, 10, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #255B0A;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23255B0A' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #255B0A;
  box-shadow: 0 0 0 0.2rem rgba(37, 91, 10, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #255B0A;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23255B0A' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #255B0A;
  box-shadow: 0 0 0 0.2rem rgba(37, 91, 10, 0.25);
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #255B0A;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #255B0A;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #255B0A;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #38890f;
  background-color: #38890f;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(37, 91, 10, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #255B0A;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #255B0A;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #255B0A;
  box-shadow: 0 0 0 0.2rem rgba(37, 91, 10, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #C00010;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(192, 0, 16, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #C00010;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23C00010' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23C00010' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #C00010;
  box-shadow: 0 0 0 0.2rem rgba(192, 0, 16, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #C00010;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23C00010' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23C00010' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #C00010;
  box-shadow: 0 0 0 0.2rem rgba(192, 0, 16, 0.25);
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #C00010;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #C00010;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #C00010;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f30014;
  background-color: #f30014;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(192, 0, 16, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #C00010;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #C00010;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #C00010;
  box-shadow: 0 0 0 0.2rem rgba(192, 0, 16, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1101px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #aa7a1a;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -2px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -2px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -2px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -2px;
}

.input-group-prepend {
  margin-right: -2px;
}

.input-group-append {
  margin-left: -2px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 2px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 4px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 4px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  /*padding-left: 0;
  list-style: none;*/
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -2px;
  line-height: 1.25;
  color: #aa7a1a;
  background-color: #fff;
  border: 2px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #684a10;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(170, 122, 26, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #aa7a1a;
  border-color: #aa7a1a;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #aa7a1a;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #7e5a13;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(170, 122, 26, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #255B0A;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #122d05;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(37, 91, 10, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #C00010;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #8d000c;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(192, 0, 16, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #ccc;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #b3b3b3;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #000;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: black;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-blue {
  color: #fff;
  background-color: #115577;
}
a.badge-blue:hover, a.badge-blue:focus {
  color: #fff;
  background-color: #0b354a;
}
a.badge-blue:focus, a.badge-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(17, 85, 119, 0.5);
}

.badge-blue-dark {
  color: #fff;
  background-color: #0D4764;
}
a.badge-blue-dark:hover, a.badge-blue-dark:focus {
  color: #fff;
  background-color: #072737;
}
a.badge-blue-dark:focus, a.badge-blue-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 71, 100, 0.5);
}

.badge-blue-darkest {
  color: #fff;
  background-color: #043246;
}
a.badge-blue-darkest:hover, a.badge-blue-darkest:focus {
  color: #fff;
  background-color: #011016;
}
a.badge-blue-darkest:focus, a.badge-blue-darkest.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(4, 50, 70, 0.5);
}

.badge-silver-light {
  color: #212529;
  background-color: #e8e8e8;
}
a.badge-silver-light:hover, a.badge-silver-light:focus {
  color: #212529;
  background-color: #cfcfcf;
}
a.badge-silver-light:focus, a.badge-silver-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(232, 232, 232, 0.5);
}

.badge-silver-lighter {
  color: #212529;
  background-color: #eee;
}
a.badge-silver-lighter:hover, a.badge-silver-lighter:focus {
  color: #212529;
  background-color: #d5d5d5;
}
a.badge-silver-lighter:focus, a.badge-silver-lighter.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5);
}

.badge-silver-lightest {
  color: #212529;
  background-color: #fbfbfb;
}
a.badge-silver-lightest:hover, a.badge-silver-lightest:focus {
  color: #212529;
  background-color: #e2e2e2;
}
a.badge-silver-lightest:focus, a.badge-silver-lightest.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(251, 251, 251, 0.5);
}

.badge-silver {
  color: #212529;
  background-color: #ccc;
}
a.badge-silver:hover, a.badge-silver:focus {
  color: #212529;
  background-color: #b3b3b3;
}
a.badge-silver:focus, a.badge-silver.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5);
}

.badge-silver-dark {
  color: #fff;
  background-color: #888;
}
a.badge-silver-dark:hover, a.badge-silver-dark:focus {
  color: #fff;
  background-color: #6f6f6f;
}
a.badge-silver-dark:focus, a.badge-silver-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(136, 136, 136, 0.5);
}

.badge-silver-darker {
  color: #fff;
  background-color: #555;
}
a.badge-silver-darker:hover, a.badge-silver-darker:focus {
  color: #fff;
  background-color: #3c3c3c;
}
a.badge-silver-darker:focus, a.badge-silver-darker.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(85, 85, 85, 0.5);
}

.badge-silver-darkest {
  color: #fff;
  background-color: #333;
}
a.badge-silver-darkest:hover, a.badge-silver-darkest:focus {
  color: #fff;
  background-color: #1a1a1a;
}
a.badge-silver-darkest:focus, a.badge-silver-darkest.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
}

.badge-red {
  color: #fff;
  background-color: #C00010;
}
a.badge-red:hover, a.badge-red:focus {
  color: #fff;
  background-color: #8d000c;
}
a.badge-red:focus, a.badge-red.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(192, 0, 16, 0.5);
}

.badge-red-dark {
  color: #fff;
  background-color: #A1000D;
}
a.badge-red-dark:hover, a.badge-red-dark:focus {
  color: #fff;
  background-color: #6e0009;
}
a.badge-red-dark:focus, a.badge-red-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(161, 0, 13, 0.5);
}

.badge-red-darkest {
  color: #fff;
  background-color: #7D000A;
}
a.badge-red-darkest:hover, a.badge-red-darkest:focus {
  color: #fff;
  background-color: #4a0006;
}
a.badge-red-darkest:focus, a.badge-red-darkest.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(125, 0, 10, 0.5);
}

.badge-green {
  color: #212529;
  background-color: #71B750;
}
a.badge-green:hover, a.badge-green:focus {
  color: #212529;
  background-color: #5a963e;
}
a.badge-green:focus, a.badge-green.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(113, 183, 80, 0.5);
}

.badge-green-dark {
  color: #fff;
  background-color: #255B0A;
}
a.badge-green-dark:hover, a.badge-green-dark:focus {
  color: #fff;
  background-color: #122d05;
}
a.badge-green-dark:focus, a.badge-green-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(37, 91, 10, 0.5);
}

.badge-green-darkest {
  color: #fff;
  background-color: #1B4606;
}
a.badge-green-darkest:hover, a.badge-green-darkest:focus {
  color: #fff;
  background-color: #091702;
}
a.badge-green-darkest:focus, a.badge-green-darkest.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(27, 70, 6, 0.5);
}

.badge-purple {
  color: #fff;
  background-color: #553377;
}
a.badge-purple:hover, a.badge-purple:focus {
  color: #fff;
  background-color: #3c2453;
}
a.badge-purple:focus, a.badge-purple.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(85, 51, 119, 0.5);
}

.badge-purple-dark {
  color: #fff;
  background-color: #3E235A;
}
a.badge-purple-dark:hover, a.badge-purple-dark:focus {
  color: #fff;
  background-color: #251535;
}
a.badge-purple-dark:focus, a.badge-purple-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(62, 35, 90, 0.5);
}

.badge-purple-darkest {
  color: #fff;
  background-color: #2B183E;
}
a.badge-purple-darkest:hover, a.badge-purple-darkest:focus {
  color: #fff;
  background-color: #120a19;
}
a.badge-purple-darkest:focus, a.badge-purple-darkest.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(43, 24, 62, 0.5);
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #583f0e;
  background-color: #eee4d1;
  border-color: #e7dabf;
}
.alert-primary hr {
  border-top-color: #e0cfac;
}
.alert-primary .alert-link {
  color: #2c2007;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #132f05;
  background-color: #d3dece;
  border-color: #c2d1ba;
}
.alert-success hr {
  border-top-color: #b4c7ab;
}
.alert-success .alert-link {
  color: #000100;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #640008;
  background-color: #f2cccf;
  border-color: #edb8bc;
}
.alert-danger hr {
  border-top-color: #e8a4a9;
}
.alert-danger .alert-link {
  color: #310004;
}

.alert-light {
  color: #6a6a6a;
  background-color: whitesmoke;
  border-color: #f1f1f1;
}
.alert-light hr {
  border-top-color: #e4e4e4;
}
.alert-light .alert-link {
  color: #515151;
}

.alert-dark {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8;
}
.alert-dark hr {
  border-top-color: #ababab;
}
.alert-dark .alert-link {
  color: black;
}

.alert-blue {
  color: #092c3e;
  background-color: #cfdde4;
  border-color: #bccfd9;
}
.alert-blue hr {
  border-top-color: #acc3d0;
}
.alert-blue .alert-link {
  color: #030c11;
}

.alert-blue-dark {
  color: #072534;
  background-color: #cfdae0;
  border-color: #bbcbd4;
}
.alert-blue-dark hr {
  border-top-color: #abbfca;
}
.alert-blue-dark .alert-link {
  color: #010507;
}

.alert-blue-darkest {
  color: #021a24;
  background-color: #cdd6da;
  border-color: #b9c6cb;
}
.alert-blue-darkest hr {
  border-top-color: #aabac0;
}
.alert-blue-darkest .alert-link {
  color: black;
}

.alert-silver-light {
  color: #797979;
  background-color: #fafafa;
  border-color: #f9f9f9;
}
.alert-silver-light hr {
  border-top-color: #ececec;
}
.alert-silver-light .alert-link {
  color: #606060;
}

.alert-silver-lighter {
  color: #7c7c7c;
  background-color: #fcfcfc;
  border-color: #fafafa;
}
.alert-silver-lighter hr {
  border-top-color: #ededed;
}
.alert-silver-lighter .alert-link {
  color: #636363;
}

.alert-silver-lightest {
  color: #838383;
  background-color: #fefefe;
  border-color: #fefefe;
}
.alert-silver-lightest hr {
  border-top-color: #f1f1f1;
}
.alert-silver-lightest .alert-link {
  color: #6a6a6a;
}

.alert-silver {
  color: #6a6a6a;
  background-color: whitesmoke;
  border-color: #f1f1f1;
}
.alert-silver hr {
  border-top-color: #e4e4e4;
}
.alert-silver .alert-link {
  color: #515151;
}

.alert-silver-dark {
  color: #474747;
  background-color: #e7e7e7;
  border-color: #dedede;
}
.alert-silver-dark hr {
  border-top-color: #d1d1d1;
}
.alert-silver-dark .alert-link {
  color: #2e2e2e;
}

.alert-silver-darker {
  color: #2c2c2c;
  background-color: #dddddd;
  border-color: #cfcfcf;
}
.alert-silver-darker hr {
  border-top-color: #c2c2c2;
}
.alert-silver-darker .alert-link {
  color: #131313;
}

.alert-silver-darkest {
  color: #1b1b1b;
  background-color: #d6d6d6;
  border-color: #c6c6c6;
}
.alert-silver-darkest hr {
  border-top-color: #b9b9b9;
}
.alert-silver-darkest .alert-link {
  color: #020202;
}

.alert-red {
  color: #640008;
  background-color: #f2cccf;
  border-color: #edb8bc;
}
.alert-red hr {
  border-top-color: #e8a4a9;
}
.alert-red .alert-link {
  color: #310004;
}

.alert-red-dark {
  color: #540007;
  background-color: #eccccf;
  border-color: #e5b8bb;
}
.alert-red-dark hr {
  border-top-color: #dea5a9;
}
.alert-red-dark .alert-link {
  color: #210003;
}

.alert-red-darkest {
  color: #410005;
  background-color: #e5ccce;
  border-color: #dbb8ba;
}
.alert-red-darkest hr {
  border-top-color: #d2a7aa;
}
.alert-red-darkest .alert-link {
  color: #0e0001;
}

.alert-green {
  color: #3b5f2a;
  background-color: #e3f1dc;
  border-color: #d7ebce;
}
.alert-green hr {
  border-top-color: #c8e4bc;
}
.alert-green .alert-link {
  color: #253c1a;
}

.alert-green-dark {
  color: #132f05;
  background-color: #d3dece;
  border-color: #c2d1ba;
}
.alert-green-dark hr {
  border-top-color: #b4c7ab;
}
.alert-green-dark .alert-link {
  color: #000100;
}

.alert-green-darkest {
  color: #0e2403;
  background-color: #d1dacd;
  border-color: #bfcbb9;
}
.alert-green-darkest hr {
  border-top-color: #b2c0aa;
}
.alert-green-darkest .alert-link {
  color: black;
}

.alert-purple {
  color: #2c1b3e;
  background-color: #ddd6e4;
  border-color: #cfc6d9;
}
.alert-purple hr {
  border-top-color: #c2b7cf;
}
.alert-purple .alert-link {
  color: #130c1a;
}

.alert-purple-dark {
  color: #20122f;
  background-color: #d8d3de;
  border-color: #c9c1d1;
}
.alert-purple-dark hr {
  border-top-color: #bcb2c6;
}
.alert-purple-dark .alert-link {
  color: #07040a;
}

.alert-purple-darkest {
  color: #160c20;
  background-color: #d5d1d8;
  border-color: #c4bec9;
}
.alert-purple-darkest hr {
  border-top-color: #b7b0bd;
}
.alert-purple-darkest .alert-link {
  color: black;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #aa7a1a;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -2px;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #aa7a1a;
  border-color: #aa7a1a;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item {
  margin-right: -2px;
  margin-bottom: 0;
}
.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -2px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -2px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 1101px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -2px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -2px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:last-child {
  margin-bottom: -2px;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #583f0e;
  background-color: #e7dabf;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #583f0e;
  background-color: #e0cfac;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #583f0e;
  border-color: #583f0e;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #132f05;
  background-color: #c2d1ba;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #132f05;
  background-color: #b4c7ab;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #132f05;
  border-color: #132f05;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #640008;
  background-color: #edb8bc;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #640008;
  background-color: #e8a4a9;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #640008;
  border-color: #640008;
}

.list-group-item-light {
  color: #6a6a6a;
  background-color: #f1f1f1;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #6a6a6a;
  background-color: #e4e4e4;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #6a6a6a;
  border-color: #6a6a6a;
}

.list-group-item-dark {
  color: black;
  background-color: #b8b8b8;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: black;
  background-color: #ababab;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: black;
  border-color: black;
}

.list-group-item-blue {
  color: #092c3e;
  background-color: #bccfd9;
}
.list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
  color: #092c3e;
  background-color: #acc3d0;
}
.list-group-item-blue.list-group-item-action.active {
  color: #fff;
  background-color: #092c3e;
  border-color: #092c3e;
}

.list-group-item-blue-dark {
  color: #072534;
  background-color: #bbcbd4;
}
.list-group-item-blue-dark.list-group-item-action:hover, .list-group-item-blue-dark.list-group-item-action:focus {
  color: #072534;
  background-color: #abbfca;
}
.list-group-item-blue-dark.list-group-item-action.active {
  color: #fff;
  background-color: #072534;
  border-color: #072534;
}

.list-group-item-blue-darkest {
  color: #021a24;
  background-color: #b9c6cb;
}
.list-group-item-blue-darkest.list-group-item-action:hover, .list-group-item-blue-darkest.list-group-item-action:focus {
  color: #021a24;
  background-color: #aabac0;
}
.list-group-item-blue-darkest.list-group-item-action.active {
  color: #fff;
  background-color: #021a24;
  border-color: #021a24;
}

.list-group-item-silver-light {
  color: #797979;
  background-color: #f9f9f9;
}
.list-group-item-silver-light.list-group-item-action:hover, .list-group-item-silver-light.list-group-item-action:focus {
  color: #797979;
  background-color: #ececec;
}
.list-group-item-silver-light.list-group-item-action.active {
  color: #fff;
  background-color: #797979;
  border-color: #797979;
}

.list-group-item-silver-lighter {
  color: #7c7c7c;
  background-color: #fafafa;
}
.list-group-item-silver-lighter.list-group-item-action:hover, .list-group-item-silver-lighter.list-group-item-action:focus {
  color: #7c7c7c;
  background-color: #ededed;
}
.list-group-item-silver-lighter.list-group-item-action.active {
  color: #fff;
  background-color: #7c7c7c;
  border-color: #7c7c7c;
}

.list-group-item-silver-lightest {
  color: #838383;
  background-color: #fefefe;
}
.list-group-item-silver-lightest.list-group-item-action:hover, .list-group-item-silver-lightest.list-group-item-action:focus {
  color: #838383;
  background-color: #f1f1f1;
}
.list-group-item-silver-lightest.list-group-item-action.active {
  color: #fff;
  background-color: #838383;
  border-color: #838383;
}

.list-group-item-silver {
  color: #6a6a6a;
  background-color: #f1f1f1;
}
.list-group-item-silver.list-group-item-action:hover, .list-group-item-silver.list-group-item-action:focus {
  color: #6a6a6a;
  background-color: #e4e4e4;
}
.list-group-item-silver.list-group-item-action.active {
  color: #fff;
  background-color: #6a6a6a;
  border-color: #6a6a6a;
}

.list-group-item-silver-dark {
  color: #474747;
  background-color: #dedede;
}
.list-group-item-silver-dark.list-group-item-action:hover, .list-group-item-silver-dark.list-group-item-action:focus {
  color: #474747;
  background-color: #d1d1d1;
}
.list-group-item-silver-dark.list-group-item-action.active {
  color: #fff;
  background-color: #474747;
  border-color: #474747;
}

.list-group-item-silver-darker {
  color: #2c2c2c;
  background-color: #cfcfcf;
}
.list-group-item-silver-darker.list-group-item-action:hover, .list-group-item-silver-darker.list-group-item-action:focus {
  color: #2c2c2c;
  background-color: #c2c2c2;
}
.list-group-item-silver-darker.list-group-item-action.active {
  color: #fff;
  background-color: #2c2c2c;
  border-color: #2c2c2c;
}

.list-group-item-silver-darkest {
  color: #1b1b1b;
  background-color: #c6c6c6;
}
.list-group-item-silver-darkest.list-group-item-action:hover, .list-group-item-silver-darkest.list-group-item-action:focus {
  color: #1b1b1b;
  background-color: #b9b9b9;
}
.list-group-item-silver-darkest.list-group-item-action.active {
  color: #fff;
  background-color: #1b1b1b;
  border-color: #1b1b1b;
}

.list-group-item-red {
  color: #640008;
  background-color: #edb8bc;
}
.list-group-item-red.list-group-item-action:hover, .list-group-item-red.list-group-item-action:focus {
  color: #640008;
  background-color: #e8a4a9;
}
.list-group-item-red.list-group-item-action.active {
  color: #fff;
  background-color: #640008;
  border-color: #640008;
}

.list-group-item-red-dark {
  color: #540007;
  background-color: #e5b8bb;
}
.list-group-item-red-dark.list-group-item-action:hover, .list-group-item-red-dark.list-group-item-action:focus {
  color: #540007;
  background-color: #dea5a9;
}
.list-group-item-red-dark.list-group-item-action.active {
  color: #fff;
  background-color: #540007;
  border-color: #540007;
}

.list-group-item-red-darkest {
  color: #410005;
  background-color: #dbb8ba;
}
.list-group-item-red-darkest.list-group-item-action:hover, .list-group-item-red-darkest.list-group-item-action:focus {
  color: #410005;
  background-color: #d2a7aa;
}
.list-group-item-red-darkest.list-group-item-action.active {
  color: #fff;
  background-color: #410005;
  border-color: #410005;
}

.list-group-item-green {
  color: #3b5f2a;
  background-color: #d7ebce;
}
.list-group-item-green.list-group-item-action:hover, .list-group-item-green.list-group-item-action:focus {
  color: #3b5f2a;
  background-color: #c8e4bc;
}
.list-group-item-green.list-group-item-action.active {
  color: #fff;
  background-color: #3b5f2a;
  border-color: #3b5f2a;
}

.list-group-item-green-dark {
  color: #132f05;
  background-color: #c2d1ba;
}
.list-group-item-green-dark.list-group-item-action:hover, .list-group-item-green-dark.list-group-item-action:focus {
  color: #132f05;
  background-color: #b4c7ab;
}
.list-group-item-green-dark.list-group-item-action.active {
  color: #fff;
  background-color: #132f05;
  border-color: #132f05;
}

.list-group-item-green-darkest {
  color: #0e2403;
  background-color: #bfcbb9;
}
.list-group-item-green-darkest.list-group-item-action:hover, .list-group-item-green-darkest.list-group-item-action:focus {
  color: #0e2403;
  background-color: #b2c0aa;
}
.list-group-item-green-darkest.list-group-item-action.active {
  color: #fff;
  background-color: #0e2403;
  border-color: #0e2403;
}

.list-group-item-purple {
  color: #2c1b3e;
  background-color: #cfc6d9;
}
.list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
  color: #2c1b3e;
  background-color: #c2b7cf;
}
.list-group-item-purple.list-group-item-action.active {
  color: #fff;
  background-color: #2c1b3e;
  border-color: #2c1b3e;
}

.list-group-item-purple-dark {
  color: #20122f;
  background-color: #c9c1d1;
}
.list-group-item-purple-dark.list-group-item-action:hover, .list-group-item-purple-dark.list-group-item-action:focus {
  color: #20122f;
  background-color: #bcb2c6;
}
.list-group-item-purple-dark.list-group-item-action.active {
  color: #fff;
  background-color: #20122f;
  border-color: #20122f;
}

.list-group-item-purple-darkest {
  color: #160c20;
  background-color: #c4bec9;
}
.list-group-item-purple-darkest.list-group-item-action:hover, .list-group-item-purple-darkest.list-group-item-action:focus {
  color: #160c20;
  background-color: #b7b0bd;
}
.list-group-item-purple-darkest.list-group-item-action.active {
  color: #fff;
  background-color: #160c20;
  border-color: #160c20;
}

/*.close {
  float: right;
  @include font-size($close-font-size);
  font-weight: $close-font-weight;
  line-height: 1;
  color: $close-color;
  text-shadow: $close-text-shadow;
  opacity: .5;

  // Override <a>'s hover style
  @include hover {
    color: $close-color;
    text-decoration: none;
  }

  &:not(:disabled):not(.disabled) {
    @include hover-focus {
      opacity: .75;
    }
  }
}*/
/*button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}*/
/*a.close.disabled {
  pointer-events: none;
}
*/
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.fade .modal-dialog {
  transform: translate(0, -50px);
}
.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 2px solid #ccc;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 2px solid #ccc;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 1101px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "JostVariableFont", arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

/*.popover {
  .arrow {
    width: $popover-arrow-width;
    height: $popover-arrow-height;
    margin: 0 $border-radius-lg;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}
*/
.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc((0.5rem + 2px) * -1);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 2px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc((0.5rem + 2px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 2px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc((0.5rem + 2px) * -1);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 2px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 2px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc((0.5rem + 2px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 2px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  background-color: #f7f7f7;
  border-bottom: 2px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 2px);
  border-top-right-radius: calc(0.3rem - 2px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1101px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  transform-style: preserve-3d;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  outline: none;
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  outline: none;
  position: relative;
}

.splide__slider {
  position: relative;
}

.splide__spinner {
  animation: splide-loading 1s linear infinite;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
.splide--draggable > .splide__slider > .splide__track, .splide--draggable > .splide__track {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splide--fade > .splide__slider > .splide__track > .splide__list, .splide--fade > .splide__track > .splide__list {
  display: block;
}

.splide--fade > .splide__slider > .splide__track > .splide__list > .splide__slide, .splide--fade > .splide__track > .splide__list > .splide__slide {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 0;
}

.splide--fade > .splide__slider > .splide__track > .splide__list > .splide__slide.is-active, .splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
  opacity: 1;
  position: relative;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide--ttb.is-active > .splide__slider > .splide__track > .splide__list, .splide--ttb.is-active > .splide__track > .splide__list {
  display: block;
}

.splide__arrow {
  -ms-flex-align: center;
  align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 2em;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2em;
  z-index: 1;
}

.splide__arrow svg {
  fill: #000;
  height: 1.2em;
  width: 1.2em;
}

.splide__arrow:hover {
  opacity: 0.9;
}

.splide__arrow:focus {
  outline: none;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide__pagination {
  bottom: 0.5em;
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
}

.splide__pagination__page {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: 0.7;
  padding: 0;
  transition: transform 0.2s linear;
  width: 8px;
}

.splide__pagination__page.is-active {
  background: #fff;
  transform: scale(1.4);
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: 0.9;
}

.splide__pagination__page:focus {
  outline: none;
}

.splide__progress__bar {
  background: #ccc;
  height: 3px;
}

.splide--nav > .splide__slider > .splide__track > .splide__list > .splide__slide:focus, .splide--nav > .splide__track > .splide__list > .splide__slide:focus {
  outline: none;
}

.splide--rtl > .splide__arrows .splide__arrow--prev, .splide--rtl > .splide__slider > .splide__track > .splide__arrows .splide__arrow--prev, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide--rtl > .splide__arrows .splide__arrow--prev svg, .splide--rtl > .splide__slider > .splide__track > .splide__arrows .splide__arrow--prev svg, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev svg {
  transform: scaleX(1);
}

.splide--rtl > .splide__arrows .splide__arrow--next, .splide--rtl > .splide__slider > .splide__track > .splide__arrows .splide__arrow--next, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide--rtl > .splide__arrows .splide__arrow--next svg, .splide--rtl > .splide__slider > .splide__track > .splide__arrows .splide__arrow--next svg, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide--ttb > .splide__arrows .splide__arrow, .splide--ttb > .splide__slider > .splide__track > .splide__arrows .splide__arrow, .splide--ttb > .splide__track > .splide__arrows .splide__arrow {
  left: 50%;
  transform: translate(-50%);
}

.splide--ttb > .splide__arrows .splide__arrow--prev, .splide--ttb > .splide__slider > .splide__track > .splide__arrows .splide__arrow--prev, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev {
  top: 1em;
}

.splide--ttb > .splide__arrows .splide__arrow--prev svg, .splide--ttb > .splide__slider > .splide__track > .splide__arrows .splide__arrow--prev svg, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev svg {
  transform: rotate(-90deg);
}

.splide--ttb > .splide__arrows .splide__arrow--next, .splide--ttb > .splide__slider > .splide__track > .splide__arrows .splide__arrow--next, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--next {
  bottom: 1em;
  top: auto;
}

.splide--ttb > .splide__arrows .splide__arrow--next svg, .splide--ttb > .splide__slider > .splide__track > .splide__arrows .splide__arrow--next svg, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--next svg {
  transform: rotate(90deg);
}

.splide--ttb > .splide__pagination, .splide--ttb > .splide__slider > .splide__pagination {
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  left: auto;
  padding: 1em 0;
  right: 0.5em;
  top: 0;
}

/* gradient */
lite-youtube::before {
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

/* responsive iframe with a 16:9 aspect ratio
    thanks https://css-tricks.com/responsive-iframes/
*/
lite-youtube::after {
  padding-bottom: 56.25%;
}

/* play button */
lite-youtube > .lty-playbtn {
  transform: translate3d(-50%, -50%, 0);
  /* YT's actual play button svg */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 68 48"><path fill="%23f00" fill-opacity="0.8" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"></path><path d="M 45,24 27,14 27,34" fill="%23fff"></path></svg>');
  transition: filter 0.1s cubic-bezier(0, 0, 0.2, 1);
}

lite-youtube:hover > .lty-playbtn,
lite-youtube .lty-playbtn:focus {
  filter: none;
}

/* Post-click styles */
lite-youtube.lyt-activated {
  cursor: unset;
}

lite-youtube.lyt-activated::before,
lite-youtube.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}

.lyt-visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@font-face {
  font-family: MontserratVF;
  src: url("../../fonts/MontserratVF.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+000-5FF; /* Download only latin glyphs */
}
@font-face {
  font-family: "Montserrat-fallback";
  size-adjust: -9900%;
  ascent-override: 96%;
  src: local("Arial");
  font-display: swap;
}
/*h1 {
    font-size: 1.9rem;
    line-height: 2.6rem;

    @include media-breakpoint-up(lg) {
        font-size: 2.8rem;
        line-height: 3.7rem;
    }
}

h2 {
    font-size: 1.6rem;
    line-height: 2.3rem;

    @include media-breakpoint-up(lg) {
        font-variation-settings: "wght" 550;
        font-size: 2.02em;
        line-height: 2.72rem;
    }
}

h3 {
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-variation-settings: "wght" 550
}

h4 {
    font-size: 1rem;
    line-height: 1.7rem;
    font-variation-settings: "wght" 550;

    @include media-breakpoint-up(lg) {
        font-size: 1.14rem;
        line-height: 1.94em;
    }
}

h5 {
    font-size: 1rem;
    line-height: 1.8rem;
}*/
/*.banner-placeholder {
    h1 {
        letter-spacing: 1.4px;
        font-variation-settings: "wght" 550;
    }

    h4 {
        font-size: 1.1rem;
        font-variation-settings: "wght" 550;
        line-height: 2rem;

        @include media-breakpoint-up(md) {
            font-size: 1.146rem;
            line-height: 2rem;
            letter-spacing: .3px;
        }

        a {
            font-variation-settings: "wght" 500;
        }
    }
}*/
.options-list h4 {
  font-size: 1.2em;
}

/* Only using these fonts for IE */
@font-face {
  font-family: "Montserrat-Regular";
  src: url("../../fonts/Montserrat-Regular.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../fonts/Montserrat-SemiBold.woff") format("woff");
  font-display: swap;
}
/* Firefox font fix renders banner h4 differently */
@-moz-document url-prefix() {
  .banner-placeholder h4, .body-placeholder h4 {
    font-variation-settings: "wght" 500;
  }
  .features-services h5, .how-it-works h5 {
    font-variation-settings: "wght" 600;
  }
  .body-placeholder h2, .body-placeholder h1, .body-placeholder .btn, .banner-placeholder h2, .banner-placeholder h1, .banner-placeholder .btn {
    font-variation-settings: "wght" 680;
  }
}
/*Border Radius*/
/*object fit*/
/*CMS Override*/
.sfPageContainer .sidebar-wrapper {
  top: 11%;
}
.sfPageContainer .fixed-top {
  position: relative;
}
.sfPageContainer .master-nav {
  height: inherit;
}
.sfPageContainer .master-nav .navbar-brand img {
  width: inherit;
}
.sfPageContainer .fx-account-links {
  position: relative;
  bottom: 0;
}
.sfPageContainer .fx-account-links .btn {
  color: #212529;
}
.sfPageContainer table tbody {
  background: transparent;
}
.sfPageContainer .navbar-expand-lg .navbar-collapse {
  display: block !important;
}
.sfPageContainer notification.noticeWrapper {
  display: none;
}
.sfPageContainer .second-ul {
  display: none !important;
}
.sfPageContainer .contact-header .tab-pane {
  width: 100%;
}
.sfPageContainer .contact-header .tab-pane #phone {
  width: 38%;
}
.sfPageContainer .contact-header .tab-pane #email {
  width: 5%;
}
.sfPageContainer .contact-header .tab-pane #search {
  width: 90%;
}
.sfPageContainer .modal-backdrop.fade.in {
  z-index: -1;
}
.sfPageContainer .accordion-content {
  display: block !important;
}
.sfPageContainer #CurrencyDrops .ddChild {
  background: white;
}

.sfPageEditor form {
  width: 100%;
  max-width: 100%;
}
.sfPageEditor .modal-body {
  text-align: left !important;
}
.sfPageEditor.modal-open .modal.fade.ng-isolate-scope.sf-timespan-selector-dlg {
  opacity: initial !important;
}
.sfPageEditor .sf-backend-wrp .form-group {
  display: inherit !important;
}
.sfPageEditor .modal-backdrop {
  position: inherit !important;
}
.sfPageEditor #cookieBannerWidget {
  display: none !important;
}

.tab-content h2, .body-placeholder ul.hyphen-list, .body-placeholder ul.cross-list, table,
.features-category > div, form, figure, .whatpassport-section #white-label {
  margin-top: 24px;
}
@media (min-width: 1101px) {
  .tab-content h2, .body-placeholder ul.hyphen-list, .body-placeholder ul.cross-list, table,
  .features-category > div, form, figure, .whatpassport-section #white-label {
    margin-top: 32px;
  }
}

svg {
  position: relative;
}
svg:after {
  display: block;
  content: "";
  width: 50px;
  height: 50px;
}

@media (max-width: 590px) {
  .eapp-whatsapp-chat-root-layout-component svg {
    width: 20px !important;
    height: 20px !important;
  }
  .Bubble__BubbleComponent-sc-83hmjh-3 {
    width: 40px !important;
    height: 40px !important;
  }
}
/* Olark Widget */
#olark-wrapper .olark-survey-form-req-icon svg {
  fill: #959ba1;
  color: #959ba1;
  width: 10px !important;
  height: 10px !important;
}

/*Forms*/
.form-control {
  background-color: #e8e8e8;
  border: 1px solid #ccc;
  color: #333;
}

form {
  background: white;
  padding: 2em;
  -webkit-border-radius: 0.65em;
  border-radius: 0.65em;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
}

.form-group {
  margin-bottom: 1.3em;
}
.form-group .text-muted {
  margin-top: 2px;
}

.search {
  background: transparent;
  padding: 0;
}

[data-sf-role=email-text-field-input] {
  width: 100%;
  height: calc(1.5em + 0.75rem + 4px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-variation-settings: "wght" 400;
  line-height: 1.5;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #e8e8e8;
  border: 1px solid #ccc;
  color: #333;
}

.form-dark form {
  background: #e8e8e8;
}
.form-dark form .form-control {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
}

.vue-form-generator-app {
  margin-top: 32px;
}
.vue-form-generator-app label {
  font-variation-settings: "wght" 500;
}

.vue-form-generator-app .vue-form-generator-nav .btn-next {
  width: auto !important;
}

.disclaimer p:first-child {
  font-variation-settings: "wght" 700;
}

.btn[type=submit] {
  margin: 20px auto;
  display: block;
}

.disclaimer-toggle {
  text-align: center;
}
.disclaimer-toggle .btn {
  font-size: 0.9rem;
  text-decoration: underline;
  -webkit-text-underline-position: under;
  -ms-text-underline-position: below;
  text-underline-position: under;
  box-shadow: none;
}
.disclaimer-toggle .btn.btn-small {
  padding: 7px 0;
}
.disclaimer-toggle .btn:focus {
  box-shadow: none;
}
.disclaimer-toggle p {
  font-size: 1rem;
}
.disclaimer-toggle #disclaimer {
  background: #fbfbfb;
  border-bottom-right-radius: 0.7rem;
  border-bottom-left-radius: 0.7rem;
  text-align: left;
}

.expo-next-button .btn-default {
  color: #fff;
  background-color: #aa7a1a;
  border-color: #aa7a1a;
}
.expo-next-button .btn-default:hover {
  background-color: #896215;
  border-color: #7e5a13;
}

.emigration-expo-form .country-select {
  margin-top: 2em;
}
.emigration-expo-form .country-select .checkbox {
  float: left;
  width: 50%;
}
.emigration-expo-form .country-select .checkbox label {
  margin-bottom: 1rem;
}
.emigration-expo-form .country-select strong {
  display: none;
}
.emigration-expo-form .checkbox input {
  -webkit-transform: scale(1.4, 1.4);
  border-color: #000;
  margin-right: 0.5em;
}
.emigration-expo-form .subscribe-newsletter .checkbox {
  margin-top: 1em;
  margin-bottom: 3em;
}

#stepNewForm {
  padding: 0;
  margin: 0;
  border-radius: 0;
  background: transparent;
}

#assessment .whatpassport-container .default-layout {
  padding: 0;
}
#assessment .whatpassport-container section {
  margin-top: 0;
}

/*Header Form*/
.banner-placeholder form, .banner-placeholder .vue-form-generator-app {
  background: rgba(0, 0, 0, 0.5);
}
.banner-placeholder form label, .banner-placeholder .vue-form-generator-app label {
  margin-bottom: 0;
  color: #fff;
}
.banner-placeholder form .disclaimer-toggle .btn, .banner-placeholder .vue-form-generator-app .disclaimer-toggle .btn {
  color: #fff;
}
.banner-placeholder form .disclaimer-toggle #disclaimer, .banner-placeholder .vue-form-generator-app .disclaimer-toggle #disclaimer {
  background: none;
}
.banner-placeholder form .disclaimer-toggle #disclaimer p, .banner-placeholder .vue-form-generator-app .disclaimer-toggle #disclaimer p {
  color: #fff;
}
.banner-placeholder form {
  font-size: 0.94rem;
  padding: 0.7em;
  margin-left: auto;
  margin-right: auto;
  max-width: 470px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
}
@media (max-width: 425px) {
  .banner-placeholder form {
    margin-top: 2em !important;
  }
}
@media (min-width: 1101px) {
  .banner-placeholder form {
    max-width: 375px;
    margin: 0 auto;
    padding: 0.7em 1.7em;
  }
}
.banner-placeholder form .form-group {
  margin-bottom: 0.56rem;
}
.banner-placeholder form label {
  font-size: 0.94rem;
}
.banner-placeholder form strong {
  letter-spacing: 0.4px;
}
.banner-placeholder form .form-control {
  padding: 0.2rem 0.5rem;
  background: #fff;
}
.banner-placeholder .vue-form-generator-app {
  margin-top: 0;
}
.banner-placeholder .vue-form-generator-app .vue-form-generator {
  padding: 0px 2px;
}
.banner-placeholder .vue-form-generator-app .vue-form-generator .form-group {
  margin: 5px 0 8px 0;
}
.banner-placeholder .vue-form-generator-app .vue-form-generator label {
  padding-bottom: 5px;
  font-variation-settings: "wght" 500;
}
.banner-placeholder .vue-form-generator-app .vue-form-generator fieldset {
  padding: 0 5px;
}
.banner-placeholder .vue-form-generator-app .vue-form-generator .checkboxCustom {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  [data-sf-role=form-container] .sf-form-buttons {
    display: flex;
    justify-content: space-between;
  }
}
[data-sf-role=form-container] .sf-form-buttons .btn[type=submit] {
  margin: 48px 0 0 0;
}
@media (max-width: 1100.98px) {
  [data-sf-role=form-container] .sf-form-buttons [data-sf-btn-role=next], [data-sf-role=form-container] .sf-form-buttons .sf-SubmitButton, [data-sf-role=form-container] .sf-form-buttons [data-sf-btn-role=prev] {
    width: 100%;
  }
}
[data-sf-role=form-container] .sf-form-buttons [data-sf-btn-role=next], [data-sf-role=form-container] .sf-form-buttons .sf-SubmitButton {
  color: #fff;
  background-color: #aa7a1a;
  border-color: #aa7a1a;
  order: 2;
}
[data-sf-role=form-container] .sf-form-buttons [data-sf-btn-role=prev] {
  color: #fff;
  background-color: #0c384f;
  border-color: #0c384f;
  border-radius: 0.62rem;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.18);
  transition-duration: 0.3s;
  transition-property: box-shadow, transform;
  letter-spacing: 2.1px;
  font-size: 0.86rem;
  font-variation-settings: "wght" 700;
  padding: 12px 45px;
  margin-top: 48px;
  display: inline-block;
}
[data-sf-role=form-container] .sf-form-buttons [data-sf-btn-role=prev]:hover {
  text-decoration: none;
}
@media (max-width: 1100.98px) {
  [data-sf-role=form-container] .sf-form-buttons [data-sf-btn-role=prev] {
    text-align: center;
    margin-top: 30px;
  }
}

.form-requirements {
  background-color: #fbfbfb;
  border: 1px solid #e0e0e0;
  padding: 20px;
  margin-top: 15px;
}
.form-requirements svg {
  fill: #000;
  color: #000;
  width: 18px;
  height: 18px;
}
.form-requirements p {
  font-variation-settings: "wght" 700;
  color: #333;
  font-size: 16px;
  margin-top: 0;
  margin-left: 15px;
  font-family: "Jost-Light", arial, sans-serif;
}
.form-requirements div {
  margin: 0 0 10px 0;
}

/*Tabs*/
.tab-wrapper .nav-tabs li {
  color: #115577;
  font-weight: bold;
  padding: 0.5rem 1rem;
  margin-bottom: -2px;
}
.tab-wrapper .nav-tabs li:hover {
  border: 0;
  border-bottom: 2px solid #aa7a1a;
  cursor: pointer;
}
.tab-wrapper .nav-tabs li.active {
  color: #aa7a1a;
  border: 0;
  border-bottom: 2px solid #aa7a1a;
}
.tab-wrapper .tab-content {
  padding-top: 1em;
}

/* Table codes */
table th {
  background: #fefefe;
}

/*Progress indicator*/
.progress {
  background: #e8e8e8;
  border-radius: 1.25rem;
}

.progress-bar {
  background-color: #115577;
  border-radius: 1.25rem;
}

/*Tables*/
table {
  width: 100%;
}
table td {
  vertical-align: top;
}
table td, table th {
  padding: 0.75rem;
  border: 1px solid #dee2e6 !important;
  vertical-align: middle !important;
}
table tbody {
  background: #fff;
}
table th {
  background: #ececec;
}

.table.table-borderless td, .table.table-borderless th {
  border: 0 !important;
}

.comparison-table td, .comparison-table th {
  text-align: center;
}
.comparison-table td:first-child, .comparison-table th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: #EEF0F6 !important;
}
.comparison-table td {
  vertical-align: middle;
}
.comparison-table td:first-child {
  text-align: right;
  width: 20%;
}
.comparison-table td:nth-child(even) {
  background-color: #eee;
}
.comparison-table thead th {
  color: #fff !important;
  font-weight: normal;
  background-color: #043246 !important;
}

/*Features*/
/*Border Radius*/
/*object fit*/
.features-services p {
  padding-left: 1em;
  line-height: 1.6em;
  margin-top: 10px;
  font-variation-settings: "wght" 500;
}
@media (min-width: 1101px) {
  .features-services p {
    width: 80%;
  }
}
.features-services p.read-more {
  margin-top: 0.5em;
  display: block;
  font-weight: bold;
  color: #aa7a1a;
}
.features-services.no-svg .details {
  margin-left: 1em;
}
.features-services.no-svg p {
  margin-top: 1em;
}
.features-services svg {
  display: block;
}

.how-it-works-simple.collapse-row {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .how-it-works-simple.collapse-row {
    margin-top: 40px;
  }
}
.how-it-works-simple.collapse-row .row {
  margin-bottom: 22px;
}
.how-it-works-simple.collapse-row .number {
  width: 40px;
  height: 40px;
  font-size: 1.3em;
  margin-left: 0;
  margin-right: 10px;
}
@media (min-width: 768px) {
  .how-it-works-simple.collapse-row .number {
    width: 50px;
    height: 50px;
  }
}
.how-it-works-simple.collapse-row h5 {
  margin-top: 0;
}
.how-it-works-simple.collapse-row p {
  margin-top: 10px;
}

svg.sable-logo-about {
  overflow: hidden;
  fill: inherit !important;
  color: inherit !important;
  width: 268px;
  height: inherit !important;
}

.contact-information .contact {
  margin-top: 32px;
}
.contact-information .contact svg {
  width: 30px;
  height: 30px;
}
.contact-information .contact a {
  color: #115577;
  margin-left: 0.5em;
  margin-right: 2em;
  font-size: 1.1em;
  font-weight: bold;
}
.contact-information .details {
  float: left;
}

.acc-promo-banner img {
  display: none;
}
@media (min-width: 1066px) {
  .acc-promo-banner img {
    display: block;
    width: 100%;
  }
}
@media (max-width: 1065px) {
  .acc-promo-banner .text {
    border: 10px solid #7fa0b0;
    background: #e5e6e7;
    text-align: center;
    padding: 2em;
  }
}
@media (min-width: 1066px) {
  .acc-promo-banner .text {
    position: absolute;
    top: 8%;
    left: 50%;
    width: 24%;
  }
}
@media (min-width: 1640px) {
  .acc-promo-banner .text {
    position: absolute;
    top: 9%;
    left: 50%;
    width: 18%;
  }
}
.acc-promo-banner .text h2 {
  font-size: 2.5em;
}
@media (min-width: 1066px) {
  .acc-promo-banner .text h2 {
    line-height: 50px;
  }
}
@media (min-width: 1640px) {
  .acc-promo-banner .text h2 {
    font-size: 5em;
    line-height: 83px;
  }
}
.acc-promo-banner .text p {
  margin-top: 10px;
}
@media (min-width: 1200px) {
  .acc-promo-banner .text p {
    font-size: 1.1em;
  }
}
.acc-promo-banner .text p strong {
  color: #115577;
  margin-top: 10px;
}

.roadshow-banner .blue-background {
  background: #043246;
  color: white;
  padding-bottom: 50px;
}
@media (max-width: 767.98px) {
  .roadshow-banner .blue-background {
    padding-bottom: 10px;
  }
}
.roadshow-banner .blue-background .row {
  max-width: 1254px;
  margin: 0 auto;
}
.roadshow-banner .roadshow-services ul {
  padding-inline-start: 19px;
}
@media (min-width: 1101px) {
  .roadshow-banner .spiral-roadshow-bg {
    display: block;
    background: url(/images/default-source/citizenship/spiral_circle.png) no-repeat center center;
    background-size: contain;
    height: 470px;
    width: 470px;
    max-width: 200vw;
    position: absolute;
    top: -45px;
  }
}
.roadshow-banner .spiral-roadshow-bg.right {
  display: none;
}
@media (min-width: 1101px) {
  .roadshow-banner .spiral-roadshow-bg.right {
    display: block;
    right: -124px;
  }
}
.roadshow-banner .roadshow-info h4 {
  font-weight: lighter;
}
.roadshow-banner .roadshow-info, .roadshow-banner .roadshow-services {
  padding-top: 50px;
}
.roadshow-banner .roadshow-schedule {
  padding: 1em 0;
}
@media (max-width: 767.98px) {
  .roadshow-banner .roadshow-schedule {
    margin-top: 2rem;
    padding-left: 7px;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 1em solid #043246;
  }
}
@media (min-width: 768px) {
  .roadshow-banner .roadshow-schedule {
    justify-content: center;
  }
}
.roadshow-banner .roadshow-schedule .roadshow-area {
  letter-spacing: 1px;
  color: #000;
  padding: 1em 0;
  padding-right: 1.3rem;
}
@media (min-width: 768px) {
  .roadshow-banner .roadshow-schedule .roadshow-area {
    border-right: 1px solid #aa7a1a;
    justify-content: center;
    padding: 0 1rem;
  }
}
.roadshow-banner .roadshow-schedule .roadshow-area:last-child {
  border-right: none;
}
.roadshow-banner .roadshow-schedule .roadshow-date {
  color: #aa7a1a;
  font-weight: bold;
  text-align: center;
}
.roadshow-banner .roadshow-schedule > a:last-child .roadshow-area {
  border-right: none;
}

.comparison-table svg {
  width: 30px;
  height: 30px;
}
.comparison-table p {
  margin-top: 0;
}
.comparison-table .compare-header {
  color: white;
  display: flex;
  justify-content: end;
  font-weight: bold;
  letter-spacing: 1px;
}
.comparison-table [class^=col-], .comparison-table [class*=" col-"] {
  border-bottom: 1px solid #9b9fab;
  border-right: 1px solid #9b9fab;
}
.comparison-table [class^=col-]:first-child, .comparison-table [class*=" col-"]:first-child {
  border-left: 1px solid #9b9fab;
  border-top: 1px solid #9b9fab;
}
.comparison-table .compare-items [class^=col-], .comparison-table .compare-items [class*=" col-"] {
  padding: 1em;
}
.comparison-table .compare-items .col-12 {
  background: #DBDEE7;
  font-weight: bold;
}

/*Lists*/
.hyphen-list li {
  position: relative;
}
.hyphen-list li:before {
  content: "✓";
  position: absolute;
  display: flex;
  left: -25px;
  font-weight: 700;
  width: 25px;
  height: 25px;
  font-size: 30px;
  align-items: center;
  justify-content: center;
  padding: 0px 4px;
}
.hyphen-list li:last-child {
  padding-bottom: 0;
}
.hyphen-list li ul li:before {
  content: "";
}

.hyphen-list li:before {
  content: "‐";
  font-size: 24px;
}

.ui-menu.sf-autocomplete {
  background: white;
  padding: 18px;
  list-style: none;
  margin-top: 3px;
  z-index: 10000;
}
.ui-menu.sf-autocomplete li {
  border-bottom: 1px solid #ccc;
  padding: 5px;
  list-style: none;
}

.searchResults .SearchTitle {
  margin: 3rem 0 4rem 0;
}
.searchResults .SearchTitle h3 {
  font-weight: 700;
}

#SearchWrapper {
  margin: 3rem 0 4rem 0;
}
@media (min-width: 1101px) {
  #SearchWrapper {
    margin: 5rem 0;
  }
}
#SearchWrapper .media-body {
  margin-bottom: 3.5rem;
}

/*Shimmer*/
.shine {
  background: #eee;
  background-image: linear-gradient(to right, #eee 0%, #b7b7b7 20%, #eee 40%, #eee 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

box {
  margin-top: 15px;
}
box.short {
  height: 74px;
}
box.tall {
  height: 92px;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.shimmer-div {
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
}

lines {
  height: 10px;
  margin-top: 10px;
  width: 252px;
}

.CurrencyToolkit box.currency {
  height: 92px;
}
.CurrencyToolkit box.input {
  margin-top: 26px;
  margin-left: 25px;
}
.CurrencyToolkit box.button {
  display: block !important;
  height: 71px;
  margin-top: 15px;
}

@media (max-width: 767.98px) {
  .reviews .shimmer-div:nth-child(even) {
    display: none;
  }
}
@media (min-width: 768px) {
  .reviews .shimmer-div:nth-child(odd) {
    display: none;
  }
}
@media (min-width: 1101px) {
  .reviews .shimmer-div:nth-child(odd) {
    display: inline-flex;
  }
}

.shimmer-form-bg-color {
  background: #f8f8f8;
}

.shimmer-line {
  height: 10px;
  margin-top: 10px;
  background: #777;
}

.shimmer-line-rounded {
  border-radius: 8px;
}

.shimmer-line-w-100 {
  width: 100%;
}

.shimmer-line-w-80 {
  width: 80%;
}

.shimmer-line-w-60 {
  width: 60%;
}

.shimmer-line-w-50 {
  width: 50%;
}

.shimmer-line-w-40 {
  width: 40%;
}

.shimmer-line-w-30 {
  width: 30%;
}

.shimmer-line-w-20 {
  width: 20%;
}

.shimmer-line-w-10 {
  width: 10%;
}

.shimmer-line-h-100 {
  height: 100px;
}

.shimmer-line-h-200 {
  height: 200px;
}

.shimmer-line-h-300 {
  height: 300px;
}

.shimmer-line-h-400 {
  height: 400px;
}

.shimmer-line-h-80 {
  height: 80px;
}

.shimmer-line-h-60 {
  height: 60px;
}

.shimmer-line-h-50 {
  height: 50px;
}

.shimmer-line-h-40 {
  height: 40px;
}

.shimmer-line-h-30 {
  height: 30px;
}

.shimmer-line-h-20 {
  height: 20px;
}

.shimmer-line-h-10 {
  height: 10px;
}

.shimmer-circle {
  background: #777;
  border-radius: 50%;
}

.shimmer-circle-lg {
  height: 129px;
  width: 129px;
}

.shimmer-circle-md {
  height: 65px;
  width: 65px;
}

.shimmer-circle-sm {
  height: 33px;
  width: 33px;
}

.shimmer-circle-x-sm {
  height: 17px;
  width: 17px;
}

.shimmer-wrapper {
  width: 100%;
  height: 100%;
  animation: shimmer-full-view 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@keyframes shimmer-full-view {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
@keyframes shimmer-animation {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.shimmer-animate {
  animation: shimmer-animation 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

/*@import "lite-youtube";*/