/*Forms*/

.form-control {
    background-color: $silver-light;
    border: 1px solid $silver;
    color: $silver-darkest;
}

form {
    background: white;
    padding: 2em;
    @include border-radius(0.65em);  
}

.form-group {
    margin-bottom: 1.3em;

    .text-muted {
        margin-top: 2px;
    }
}

.search {
    background: transparent;
    padding: 0;
}

//Email field
[data-sf-role="email-text-field-input"] {
    width: 100%;
    height: calc(1.5em + 0.75rem + 4px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-variation-settings: "wght" 400;
    line-height: 1.5;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background-color: #e8e8e8;
    border: 1px solid #ccc;
    color: #333;
}

.form-dark {
    form {
        background: $silver-light;

        .form-control {
            background-color: $white;
            border: 1px solid $silver;
            color: $silver-darkest;
        }
    }
}

.vue-form-generator-app {
    margin-top: 32px;

    label {
        font-variation-settings: "wght" 500;

    }
}

.vue-form-generator-app .vue-form-generator-nav .btn-next {
    width: auto !important;
}

.disclaimer {
    p {
        &:first-child {
            font-variation-settings: "wght" 700;
        }
    }
}


.btn[type=submit] {
    margin: 20px auto;
    display: block;
}

//toggle discalimer on the forms
.disclaimer-toggle {
    text-align: center;

    .btn {
        font-size: 0.9rem;
        text-decoration: underline;
        -webkit-text-underline-position: under;
        -ms-text-underline-position: below;
        text-underline-position: under;
        box-shadow: none;

        &.btn-small {
            padding: 7px 0;
        }

        &:focus {
            box-shadow: none;
        }
    }

    p {
        font-size: 1rem;
    }

    #disclaimer {
        background: $silver-lightest;
        border-bottom-right-radius: 0.7rem;
        border-bottom-left-radius: 0.7rem;
        text-align: left;
    }
}


.expo-next-button {
    .btn-default {
        color: #fff;
        background-color: #aa7a1a;
        border-color: #aa7a1a;

        &:hover {
            background-color: #896215;
            border-color: #7e5a13;
        }
    }
}

.emigration-expo-form {
    .country-select {
        margin-top: 2em;

        .checkbox {
            float: left;
            width: 50%;

            label {
                margin-bottom: 1rem;
            }
        }

        strong {
            display: none;
        }
    }

    .checkbox {
        input {
            -webkit-transform: scale(1.4, 1.4);
            border-color: #000;
            margin-right: 0.5em;
        }
    }

    .subscribe-newsletter {
        .checkbox {
            margin-top: 1em;
            margin-bottom: 3em;
        }
    }
}

#stepNewForm {
    padding: 0;
    margin: 0;
    border-radius: 0;
    background: transparent;
}

//Assessment Whatpassport form

#assessment {
    .whatpassport-container {
        .default-layout {
            padding: 0;
        }
        section {
            margin-top: 0;
        }
    }
}

/*Header Form*/
//Combining to avoid too many http requests


.banner-placeholder {
    form, .vue-form-generator-app {
        background: rgba(0, 0, 0, 0.5);

        label {
            margin-bottom: 0;
            color: #fff;
        }

        .disclaimer-toggle {
            .btn {
                color: #fff;
            }

            #disclaimer {
                background: none;

                p {
                    color: #fff;
                }
            }
        }
    }

    form {
        font-size: .94rem;
        padding: 0.7em;
        margin-left: auto;
        margin-right: auto;
        max-width: 470px;

        @include border-radius(8px);

        @media (max-width: 425px) {
            margin-top: 2em !important;
        }

        @include media-breakpoint-up(lg) {
            max-width: 375px;
            margin: 0 auto;
            padding: 0.7em 1.7em;
        }

        .form-group {
            margin-bottom: 0.56rem;
        }


        label {
            font-size: .94rem;
        }

        strong {
            letter-spacing: 0.4px;
        }

        .form-control {
            padding: .2rem .5rem;
            background: #fff;
        }
    }
    //CRM lead form
    .vue-form-generator-app {
        margin-top: 0;

        .vue-form-generator {
            padding: 0px 2px;

            .form-group {
                margin: 5px 0 8px 0;
            }

            label {
                padding-bottom: 5px;
                font-variation-settings: "wght" 500;
            }

            fieldset {
                padding: 0 5px;
            }

            .checkboxCustom {
                margin-bottom: 0;
            }
        }
    }
}

//Sitefinity forms with multiple steps
[data-sf-role="form-container"] {

    .sf-form-buttons {

        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-between;
        }

        .btn[type=submit] {
            margin: 48px 0 0 0;
        }

        [data-sf-btn-role="next"], .sf-SubmitButton, [data-sf-btn-role="prev"] {
            @include media-breakpoint-down(md) {
                width: 100%
            }
        }

        [data-sf-btn-role="next"], .sf-SubmitButton {
            color: #fff;
            background-color: #aa7a1a;
            border-color: #aa7a1a;
            order: 2;
        }

        [data-sf-btn-role="prev"] {
            color: #fff;
            background-color: #0c384f;
            border-color: #0c384f;
            border-radius: 0.62rem;
            transform: perspective(1px) translateZ(0);
            box-shadow: 0 6px 6px 0 rgb(0 0 0 / 18%);
            transition-duration: .3s;
            transition-property: box-shadow,transform;
            letter-spacing: 2.1px;
            font-size: .86rem;
            font-variation-settings: "wght" 700;
            padding: 12px 45px;
            margin-top: 48px;
            display: inline-block;

            &:hover {
                text-decoration: none;
            }

            @include media-breakpoint-down(md) {
                text-align: center;
                margin-top: 30px;
            }
        }
    }
}
    //1C Relocation Pack form requirements

.form-requirements {
    background-color: #fbfbfb;
    border: 1px solid #e0e0e0;
    padding: 20px;
    margin-top: 15px;

    svg {
        fill: #000;
        color: #000;
        width: 18px;
        height: 18px;
    }

    p {
        font-variation-settings: "wght" 700;
        color: #333;
        font-size: 16px;
        margin-top: 0;
        margin-left: 15px;
        font-family: "Jost-Light",arial,sans-serif;
    }

    div {
        margin: 0 0 10px 0;
    }
}
