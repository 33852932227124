/*CMS Override*/
.sfPageContainer {
    //Pattern Library
    .sidebar-wrapper {
        top: 11%;
    }

    .fixed-top {
        position: relative;
    }

    .master-nav {
        height: inherit;

        .navbar-brand {
            img {
                width: inherit;
            }
        }
    }

    .fx-account-links {
        position: relative;
        bottom: 0;

        .btn {
            color: #212529;
        }
    }

    table {
        tbody {
            background: transparent;
        }
    }


    .navbar-expand-lg {
        .navbar-collapse {
            display: block !important;
        }
    }

    notification.noticeWrapper {
        display: none;
    }

    .second-ul {
        display: none !important;
    }


    .contact-header {
        .tab-pane {
            width: 100%;

            #phone {
                width: 38%;
            }

            #email {
                width: 5%;
            }

            #search {
                width: 90%;
            }
        }
    }

    .modal-backdrop.fade.in {
        z-index: -1;
    }


    .accordion-content {
        display: block !important;
    }

    #CurrencyDrops {
        .ddChild {
            background: white;
        }
    }
}

.sfPageEditor {
    form {
        width: 100%;
        max-width: 100%;
    }

    .modal-body {
        text-align: left !important;
    }

    &.modal-open .modal.fade.ng-isolate-scope.sf-timespan-selector-dlg {
        opacity: initial !important;
    }

    .sf-backend-wrp .form-group {
        display: inherit !important;
    }

    .modal-backdrop {
        position: inherit !important;
    }

    #cookieBannerWidget {
        display: none !important;
    }

}
