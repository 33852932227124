/*Progress indicator*/

.progress {
    //border-bottom: 1px solid $silver;
    //background: transparent;
    background: $silver-light;
    border-radius: 1.25rem;
}

.progress-bar {
    background-color: $blue;
    border-radius: 1.25rem;
}