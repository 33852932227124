@font-face {
    font-family: MontserratVF;
    src: url("../../fonts/MontserratVF.woff2") format("woff2");
    font-display: swap; 
    unicode-range: U+000-5FF; /* Download only latin glyphs */  
}

@font-face {
    font-family: "Montserrat-fallback";
    size-adjust: -9900.00%;
    ascent-override: 96%;
    src: local("Arial");
    font-display: swap;
}

//Body typography
/*h1 {
    font-size: 1.9rem;
    line-height: 2.6rem;

    @include media-breakpoint-up(lg) {
        font-size: 2.8rem;
        line-height: 3.7rem;
    }
}

h2 {
    font-size: 1.6rem;
    line-height: 2.3rem;

    @include media-breakpoint-up(lg) {
        font-variation-settings: "wght" 550;
        font-size: 2.02em;
        line-height: 2.72rem;
    }
}

h3 {
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-variation-settings: "wght" 550
}

h4 {
    font-size: 1rem;
    line-height: 1.7rem;
    font-variation-settings: "wght" 550;

    @include media-breakpoint-up(lg) {
        font-size: 1.14rem;
        line-height: 1.94em;
    }
}

h5 {
    font-size: 1rem;
    line-height: 1.8rem;
}*/

//Banner typography
/*.banner-placeholder {
    h1 {
        letter-spacing: 1.4px;
        font-variation-settings: "wght" 550;
    }

    h4 {
        font-size: 1.1rem;
        font-variation-settings: "wght" 550;
        line-height: 2rem;

        @include media-breakpoint-up(md) {
            font-size: 1.146rem;
            line-height: 2rem;
            letter-spacing: .3px;
        }

        a {
            font-variation-settings: "wght" 500;
        }
    }
}*/

.options-list{
    h4{
        font-size: 1.2em;      
    }
}


/* Only using these fonts for IE */
@font-face {
    font-family: "Montserrat-Regular";
    src: url("../../fonts/Montserrat-Regular.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Montserrat-SemiBold";
    src: url("../../fonts/Montserrat-SemiBold.woff") format("woff");
    font-display: swap;
}

/* Firefox font fix renders banner h4 differently */
@-moz-document url-prefix() { 
    .banner-placeholder, .body-placeholder {
       h4 {
        font-variation-settings: "wght" 500;          
       }
    }

    .features-services, .how-it-works{
        h5{
            font-variation-settings: "wght" 600;          
        }
    }

    .body-placeholder, .banner-placeholder{
        h2, h1, .btn{
            font-variation-settings: "wght" 680;          
           }       
    }
}
