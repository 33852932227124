//Image and content divider
//About Us
svg.sable-logo-about {
    overflow: hidden;
    fill: inherit !important;
    color: inherit !important;
    width: 268px;
    height: inherit !important;
}

//package or accomodation
.contact-information {

    .contact {
        margin-top: 32px;

        svg {
            width: 30px;
            height: 30px;
        }

        a {
            color: $blue;
            margin-left: 0.5em;
            margin-right: 2em;
            font-size: 1.1em;
            font-weight: bold;
        }
    }

    .details {
        float: left;
    }
}

//Accounting Promo Banner
.acc-promo-banner {

    img {
        display: none;

        @media (min-width:1066px) {
            display: block;
            width: 100%;
        }
    }

    .text {
        @media (max-width:1065px) {
            border: 10px solid#7fa0b0;
            background: #e5e6e7;
            text-align: center;
            padding: 2em;
        }

        @media (min-width:1066px) {
            position: absolute;
            top: 8%;
            left: 50%;
            width: 24%;
        }

        @media (min-width:1640px) {
            position: absolute;
            top: 9%;
            left: 50%;
            width: 18%;
        }

        h2 {
            font-size: 2.5em;

            @media (min-width:1066px) {
                line-height: 50px;
            }



            @media (min-width:1640px) {
                font-size: 5em;
                line-height: 83px;
            }
        }

        p {
            margin-top: 10px;

            @include media-breakpoint-up(xl) {
                font-size: 1.1em;
            }

            strong {
                color: $blue;
                margin-top: 10px;
            }
        }
    }
}

.roadshow-banner {
    .blue-background {
        background: #043246;
        color: white;
        padding-bottom: 50px;

        @include media-breakpoint-down(sm) {
            padding-bottom: 10px;
        }

        .row {
            max-width: 1254px;
            margin: 0 auto;
        }
    }

    .roadshow-services ul {
        padding-inline-start: 19px;
    }

    .spiral-roadshow-bg {

        @include media-breakpoint-up(lg) {
            display: block;
            background: url(/images/default-source/citizenship/spiral_circle.png) no-repeat center center;
            background-size: contain;
            height: 470px;
            width: 470px;
            max-width: 200vw;
            position: absolute;
            top: -45px;
        }


        &.right {
            display: none;

            @include media-breakpoint-up(lg) {
                display: block;
                right: -124px;
            }
        }
    }

    .roadshow-info {
        h4 {
            font-weight: lighter;
        }
    }

    .roadshow-info, .roadshow-services {
        padding-top: 50px;
    }


    .roadshow-schedule {
        padding: 1em 0;


        @include media-breakpoint-down(sm) {
            margin-top: 2rem;
            padding-left: 7px;
            padding-top: 0;
            padding-bottom: 0;
            border-bottom: 1em solid #043246;
        }

        @include media-breakpoint-up(md) {
            justify-content: center;
        }

        .roadshow-area {
            letter-spacing: 1px;
            color: #000;
            padding: 1em 0;
            padding-right: 1.3rem;



            @include media-breakpoint-up(md) {
                border-right: 1px solid $primary;
                justify-content: center;
                padding: 0 1rem;
            }

            &:last-child {
                border-right: none;
            }
        }

        .roadshow-date {
            color: $primary;
            font-weight: bold;
            text-align: center;
        }

        > a:last-child {
            .roadshow-area {
                border-right: none;
            }
        }
    }
}

.comparison-table {

    svg {
        width: 30px;
        height: 30px;
    }

    p {
        margin-top: 0;
    }

    .compare-header {
        color: white;
        display: flex;
        justify-content: end;
        font-weight: bold;
        letter-spacing: 1px;
    }

    [class^="col-"], [class*=" col-"] {
        border-bottom: 1px solid #9b9fab;
        border-right: 1px solid #9b9fab;

        &:first-child {
            border-left: 1px solid #9b9fab;
            border-top: 1px solid #9b9fab;
        }
    }

    .compare-items {
        [class^="col-"], [class*=" col-"] {
            padding: 1em
        }

        .col-12 {
            background: #DBDEE7;
            font-weight: bold;
        }
    }
}