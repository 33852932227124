﻿//margin top spacing paragraphs, buttons, ul, tables
.tab-content h2, .body-placeholder ul.hyphen-list, .body-placeholder ul.cross-list, table,
.features-category > div, form, figure, .whatpassport-section #white-label {
    margin-top: 24px;

    @include media-breakpoint-up(lg) {
        margin-top: 32px;
    }
}

svg {
    position: relative;

    &:after {
        display: block;
        content: "";
        width: 50px;
        height: 50px;
    }
}

//Whatsapp icon
@media (max-width:590px) {
    .eapp-whatsapp-chat-root-layout-component svg {
        width: 20px !important;
        height: 20px !important;
    }

    .Bubble__BubbleComponent-sc-83hmjh-3 {
        width: 40px !important;
        height: 40px !important;
    }
}

/* Olark Widget */
#olark-wrapper .olark-survey-form-req-icon svg {
    fill: #959ba1;
    color: #959ba1;
    width: 10px !important;
    height: 10px !important;
}
