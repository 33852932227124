/*Tabs*/
.tab-wrapper {
    .nav-tabs {
        li {
            color: $blue;
            font-weight: bold;
            padding: .5rem 1rem;
            margin-bottom: -2px;

            &:hover {
                border: 0;
                border-bottom: 2px solid $gold;
                cursor: pointer;
            }

            &.active {
                color: $gold;
                border: 0;
                border-bottom: 2px solid $gold;
            }
        }
    }

    .tab-content {
        padding-top: 1em;

 
 
    }
}

/* Table codes */

table{
    th{
        background: #fefefe;
    }
}