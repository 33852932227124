/*Features*/

//Imports Sable and Bootstraps mixins,variables and functions
@import "../helpers/_helpers-dir.scss";

//Features
.features-services {
    p {
        padding-left: 1em;
        line-height: 1.6em;
        margin-top: 10px;
        font-variation-settings: "wght" 500;

        @include media-breakpoint-up(lg) {
            width: 80%;
        }
    }

    p.read-more {
        margin-top: 0.5em;
        display: block;
        font-weight: bold;
        color: $primary;
    }

    &.no-svg {

        .details {
            margin-left: 1em;
        }

        p {
            margin-top: 1em;
        }
    }

    svg {
        display: block;
    }
}

// 3 steps rounded circle numbers
.how-it-works-simple {
    &.collapse-row {
        margin-top: 20px;

        @include media-breakpoint-up(md) {
            margin-top: 40px;
        }

        .row {
            margin-bottom: 22px;
        }

        .number {
            width: 40px;
            height: 40px;
            font-size: 1.3em;
            margin-left: 0;
            margin-right: 10px;

            @include media-breakpoint-up(md) {
                width: 50px;
                height: 50px;
            }
        }

        h5 {
            margin-top: 0;
        }

        p {
            margin-top: 10px;
        }
    }
}
