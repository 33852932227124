/*Lists*/
.hyphen-list {
    li {
        position: relative;

        &:before {
            content: "\2713";
            position: absolute;
            display: flex;
            left: -25px;
            font-weight: 700;
            width: 25px;
            height: 25px;
            font-size: 30px;
            align-items: center;
            justify-content: center;
            padding: 0px 4px;
        }

        &:last-child {
            padding-bottom: 0;
        }

        ul {
            li {
                &:before {
                    content: "";
                }
            }
        }
    }
}

.hyphen-list {

    li {
        &:before {
            content: "\2010";
            font-size: 24px;
        }
    }
}

